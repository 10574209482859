import React from 'react';

const initialState = {
    mobile: false,
    videoRef: null,
    menuOpen: false
}
export const GlobalStateContext = React.createContext();
export const GlobalDispatchContext = React.createContext();



function reducer(state, action) {
    console.log(state);

    switch (action.type) {
        case "TOGGLE_MOBILE": {
            return {
                ...state,
                mobile: !state.mobile
            }
        }
        case "IS_MOBILE": {
            return {
                ...state,
                mobile: true
            }
        }
        case "SET_VIDEO": {
            return {
                ...state,
                videoRef: action.videoRef
            }
        }
        case "MENU": {
            return {
                ...state,
                menuOpen: action.status
            }
        }
        default:
            throw new Error("Bad action");

    }
}
const GlobalContextProvider = ({children}) => {
    const [state, dispatch] = React.useReducer(reducer, initialState, ()=>{return {mobile:false, videoRef:null, menuOpen:false}});
    return <GlobalStateContext.Provider value={state}><GlobalDispatchContext.Provider value={dispatch}>{children}</GlobalDispatchContext.Provider></GlobalStateContext.Provider>
}

export default GlobalContextProvider